import { useState, useRef, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import { DownArrow } from "~/old-app/assets/icons";
import listenForOutsideClick from "~/old-app/utils/helpers/listen-for-outside-clicks";
import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
const DisplayCountDropdown = ({ options, limit, setLimit }) => {
  const { t } = useTranslation();
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = useRef(null);
  const popoverDropdownRef = useRef(null);

  useEffect(() => {
    if (dropdownPopoverShow) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });

      const { addEventListeners, removeEventListeners } = listenForOutsideClick(
        setDropdownPopoverShow,
        btnDropdownRef,
        popoverDropdownRef
      );

      addEventListeners();

      return () => {
        removeEventListeners();
      };
    }
  }, [dropdownPopoverShow]);

  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  return (
    <>
      {/* <span className="hidden md:flex text-sm leading-1 capitalize font-medium text-gray-2000">
        {t("filter.display")}
      </span> */}
      <button
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        className="hidden md:flex items-center gap-2 border border-gray-80 p-2 text-typo-700 font-medium  text-xs rounded-sm w-[7rem]"
      >
        {limit} {t("filter.perPage")}
        <DownArrow />
      </button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-start py-2 list-none text-start rounded shadow-lg min-w-[10rem]"
        }
      >
        {options?.length > 0 ? (
          options.map((option) => (
            <div
              key={`limit-${option.count}`}
              className={`flex items-center cursor-pointer gap-2 hover:text-primary py-2 px-4 text-xs w-full whitespace-nowrap bg-transparent text-typo-700`}
              onClick={() => {
                closeDropdownPopover();
                setLimit?.(option.count);
              }}
            >
              {option?.count} {t("filter.perPage")}
            </div>
          ))
        ) : (
          <p
            className={
              "text-xs pt-2 pb-0 px-4 font-regular block w-full whitespace-nowrap bg-transparent text-typo-300"
            }
          >
            {t("global.emptyDescription")}
          </p>
        )}
      </div>
    </>
  );
};

export default DisplayCountDropdown;
